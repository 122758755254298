import React from 'react';
import { TypescriptLogo, ReactLogo, NodeJS, AdvancedReactLogo } from './LogoProIcons';
import { mediaQueries } from 'src/style/breakpoints';

/**
 * @type React.FunctionComponent<{ name: string }>
 */
const LogoProTechno = ({ name, deviceDisplay }) => {
  return (
    <div
      css={{
        display: deviceDisplay === 'isDesktop' ? 'none' : 'flex',
        width: 150,
        height: '100%',
        flexDirection: 'column',
        justifyContent: 'flex-start',
        [mediaQueries.desktopUp]: {
          display: deviceDisplay === 'isDesktop' ? 'flex' : 'none',
        },
      }}
    >
      <div
        css={{
          height: 115,
          width: '100%',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          margin: '12px 0',
        }}
      >
        {name === 'node-js' ? (
          <NodeJS deviceDisplay={deviceDisplay} />
        ) : name === 'react-redux' ? (
          <ReactLogo deviceDisplay={deviceDisplay} />
        ) : name === 'react-native' ? (
          <ReactLogo deviceDisplay={deviceDisplay} />
        ) : name === 'typescript' ? (
          <TypescriptLogo deviceDisplay={deviceDisplay} />
        ) : name === 'react-avance' ? (
          <AdvancedReactLogo deviceDisplay={deviceDisplay} />
        ) : null}
      </div>
    </div>
  );
};

export default LogoProTechno;
