import { isAfter } from 'date-fns';

export function formatPostDate(date, lang) {
  if (typeof Date.prototype.toLocaleDateString !== 'function') {
    return date;
  }
  //Remove the "Z" of the Date format
  let newDate = date.substring(0, date.length - 1);

  date = new Date(newDate);
  const day = date.getDate();

  const dayValue = day !== 1 && { day: 'numeric' };
  const args = [lang, { ...dayValue, month: 'long', year: 'numeric' }].filter(Boolean);

  let result = date.toLocaleDateString(...args);
  if (day === 1) {
    return '1er ' + result;
  }
  return result;
}

const monthValue = [
  'jan.',
  'fév.',
  'mars',
  'avril',
  'mai',
  'juin',
  'juil.',
  'août',
  'sept.',
  'oct.',
  'nov.',
  'déc.',
];

const monthLongValue = [
  'janvier',
  'février',
  'mars',
  'avril',
  'mai',
  'juin',
  'juillet',
  'août',
  'septembre',
  'octobre',
  'novembre',
  'décembre',
];

export function dayMonthYearFromDate(dateValue, long = false) {
  let newDateValue = dateValue.substring(0, dateValue.length - 1);
  const date = new Date(newDateValue);
  const day = date.getDate();
  const month = long ? monthLongValue[date.getMonth()] : monthValue[date.getMonth()];
  const year = date.getFullYear();

  return { day, month, year };
}

export const formatDay = (day) => (day === 1 ? '1er' : day);

export const generateID = () => '_' + Math.random().toString(36).substr(2, 9);

export function nextSessionsFilter(sessions, training) {
  const futurSessions = sessions
    .filter((session) => {
      return (
        session.public === true &&
        session.course?.slug === training &&
        !isAfter(new Date(), new Date(session?.startAt))
      );
    })
    .slice(0, 3);
  return futurSessions;
}

export function SessionDateDisplay(nextSession, training) {
  const {
    day: startDateDay,
    month: startDateMonth,
    year: startDateYear,
  } = dayMonthYearFromDate(nextSession.startAt);

  if (training === 'Temps Plein' || training === 'Temps Partiel') {
    const {
      day: endDateDay,
      month: endDateMonth,
      year: endDateYear,
    } = dayMonthYearFromDate(nextSession.endAt);
    return `Du ${formatDay(startDateDay)} ${startDateMonth} ${
      startDateYear === endDateYear ? '' : startDateYear
    } au ${formatDay(endDateDay)} ${endDateMonth} ${endDateYear}`;
  } else {
    return `Le ${formatDay(startDateDay)} ${startDateMonth} ${startDateYear}`;
  }
}

export function handlePriceFormat(session) {
  let priceIndividualsRightFormat = '';
  let priceCorporateRightFormat = '';
  let priceRemoteRightFormat = '';
  session.prices.forEach((item) => {
    if (item.currency === 'EUR') {
      const currency = '€';
      if (item.name === 'telepresentiel' && item.visible === true) {
        let priceRemote = Math.round(item.price * (1 + item.vat / 100));
        priceRemoteRightFormat =
          new Intl.NumberFormat('fr-FR').format(priceRemote).replace(/\s/g, '.') + currency;
      }
      if (item.name === 'particuliers' && item.visible === true) {
        let priceIndividuals = Math.round(item.price * (1 + item.vat / 100));
        priceIndividualsRightFormat =
          new Intl.NumberFormat('fr-FR').format(priceIndividuals).replace(/\s/g, '.') + currency;
      }
      if (item.name === 'entreprises' && item.visible === true) {
        priceCorporateRightFormat =
          new Intl.NumberFormat('fr-FR').format(item.price).replace(/\s/g, '.') + currency;
      }
    }
  });
  return { priceIndividualsRightFormat, priceCorporateRightFormat, priceRemoteRightFormat };
}
