import styled from 'src/style';
import { mediaQueries } from 'src/style/breakpoints';

export const GalaxyIllustrationWrapper = styled.div({
  position: 'absolute',
  width: '26vw',
  right: 0,
  zIndex: 4,
  top: '50%',
  // transform: 'translateY(-25%)',
  transform: 'translateY(-74%)',

  [mediaQueries.tabletPortraitUp]: {
    top: '50%',
    // transform: 'translateY(-35%)',
    transform: 'translateY(-62%)',
    width: '24vw',
  },
  [mediaQueries.tabletLandscapeUp]: {
    // transform: 'translateY(-30%)',
    transform: 'translateY(-55%)',
    width: '20vw',
  },
  [mediaQueries.desktopUp]: {
    transform: 'translateY(-22%)',
    width: 250,
  },
  [mediaQueries.bigDesktopUp]: {
    transform: 'translateY(-35%)',
    width: 290,
  },
});
